@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.eot');
    src: url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    --black: #000000;
    --blue1: #1B02A4;
    --blue2: #0017FF;
    --blue3: #0043FF;
    --gray: #B8B8B8;
    --bone: #E1E2E5;

}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 36px;
    color: var(--bone);
    
}
h2 {
    font-size: 25px;
}

body {
    background-color: var(--black);
    text-align:center !important;
    font-family: 'Inter';
}

p {
    color: var(--gray);
}
a {
    text-decoration: none;
}

nav{
    width: 100%;
    color: var(--gray);
    overflow: hidden;
    display: flex;
}

nav ul {
    justify-content: center;
    width: 100%;
    display: flex;
    list-style: none;
    gap: 20px;
}

nav li {
    padding: 15px 50px;
}

.hoverable{
    
    transform: translateZ(0);
    transition-duration: .3s;
    transition-property:transform;
}

nav a {
    color: var(--gray);
    text-decoration: none;
}

.nav-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hoverable:before{
    position:absolute;
    pointer-events: none;
    z-index:-1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(center, ellipse, var(--blue1) 0%, var(--black) 80%);
    background: radial-gradient(ellipse at center, var(--blue1) 0%, var(--black) 80%);
    /* W3C */
    transition-duration: 0.3s;
    transition-property: transform, opacity;
}

.hoverable:hover, .hoverable:active, .hoverable:focus{
    transform: translateY(-5px);
}
.hoverable:hover:before, .hoverable:active:before, .hoverable:focus:before{
    opacity: 1;
    transform: translateY(-5px);
}

.ps {padding: 10px;}
.pm {padding: 25px;}
.pl {padding: 50px;}

.pbs {padding-bottom: 10px;}
.pbm {padding-bottom: 25px;}
.pbl {padding-bottom: 50px;}

.pts {padding-top: 10px;}
.ptm {padding-top: 25px;}
.ptl {padding-top: 50px;}

.mbs {margin-bottom: 10px;}
.mbm {margin-bottom: 25px;}
.mbl {margin-bottom: 50px;}

.mts {margin-top: 10px;}
.mtm {margin-top: 25px;}
.mtl {margin-top: 50px;}

.text-gray{color: var(--gray);}
.text-bone{color: var(--bone);}
.text-black{color: var(--black);}

.jack-title{
    display: inline;
    border-bottom: 3px solid var(--blue3);
}

.bio {
    padding: 10px;
}

.grid-container {
    display: grid;
    place-items: center;
    padding: 2rem;
    display: grid;
    column-gap: 2rem;
    row-gap: 3rem;
}



.icon{
    transition: 0.3s ease;
    color: var(--bone);
    padding: 10px;
}

footer {
    width: 100%;
}

.footer-container{
    display: flex;
    align-items: center;
    text-align: center;
    float: right;
}

@media (min-width: 600px) {
    p {
        font-size: 1.25rem;
    }
}

@media (min-width: 800px) {
    p {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
}
@media (min-width: 500px) {
    .grid-container{
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
}
@media (max-width: 500px) {
    .card {
        color: var(--gray);
        height: 600px;
        width: 80%; 
        overflow: hidden;
        text-align: center;
        display: blo;
        flex-direction: column;
        padding-bottom: 0;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 1px 1px 10px var(--blue1);
    }
    .card-info{
        font-size: smaller;
    }
    .tag{
        font-size: smaller;
    }
    .p{
        font-size: x-small;
    }
    .grid-container {
        display: block;
        place-items: center;
        padding: 0px;
        padding-left: -20px;
        display: grid;
        column-gap: 1rem;
        row-gap: 3rem;
        width: 100%;
    }

    nav li {
        padding: 15px 20px;
    }
}