:root {
    --black: #000000;
    --blue1: #1B02A4;
    --blue2: #0017FF;
    --blue3: #0043FF;
    --gray: #B8B8B8;
    --bone: #E1E2E5;
}

.card {
    color: var(--bone);
    height: fit-content;
    width: 400px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;

    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 1px 1px 10px var(--blue1);
    padding: 20px;
}

.card:hover {
    box-shadow: 1px 1px 20px var(--blue2);
}

.thumbnail {
    width: 100%;
    height: 350px;
    object-fit: cover;
    overflow: hidden;
}

.card-info{
    width: 100%;
    padding: 0 25px;
    color: var(--bone);
    line-height: 24px;

    font-size: 1rem;
}

.tag-list{
    display: flex;
    justify-content: space-around;
    color: var(--bone);
    border: 1px solid var(--blue1);
    background-color: var(--black);
    border-radius: 16px;
}

.tag-list:hover{
    border: 1px solid var(--blue2);
}

.tag {
    font-size: 1rem;
}

.external-link {
    color:var(--bone);
    padding: 5px;
    width: 100%;
    background: var(--blue1);
    border-radius: 5px;
    transition: 0.5s;
    font-weight: 300%;
}
.external-link:hover {
    background-color: var(--blue3);
}
.external-link:active {
    background-color: var(--blue1);
}

