:root {
    --black: #000000;
    --blue1: #1B02A4;
    --blue2: #0017FF;
    --blue3: #0043FF;
    --gray: #B8B8B8;
    --bone: #E1E2E5;

}

.contact {
    max-width: 480px;
    width: 100%;
    padding: 60px 40px;
    margin: 0 auto;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px 0;
}

input {
    background: none;
    border: none;
    border-bottom: 1px solid var(--pink);
    font-family: var(--regular);
    color: #fff;
    padding: 0 2px;
}
textarea {
    background: none;
    border: 1px solid var(--pink);
    font-family: var(--regular);
    color: #fff;
    padding: 2px;
    height: 60px;
    resize: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
input::placeholder, textarea::placeholder {
    color: #fff;
    opacity: 1;
}
input:focus, textarea:focus {
    outline: none;
}
input:focus::placeholder, textarea:focus::placeholder {
    opacity: 0.7;
}



/* Submit */
.submit-wrapper {
    position: relative;
    height: 38px;
}
.submit,
.submit-wrapper span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.submit {
    border: none;
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    font-family: var(--medium);
    color: var(--darkblue);
    z-index: 3;
}

.submit-wrapper span:before, .submit-wrapper span:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.submit-wrapper span::before {
    background: var(--lightblue);
    z-index: 1;
}
.submit-wrapper span::after {
    background: var(--gradient);
    z-index: 2;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.pre::after {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
}
.post::after {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}

@media screen and (max-width: 680px) {
    .contact {
        padding: 40px 15px;
    }
}